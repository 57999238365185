@mixin reset-button() {
  vertical-align: top;
  font-family: inherit;
  text-align: left;
  background: none;
  line-height: normal;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  outline:none;
  text-decoration: none;
  -webkit-border-radius:0;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@mixin reset-input() {
  border: none;
  vertical-align: top;
  font-family: inherit;
  text-align: left;
  background: none;
  line-height: normal;
  border: none;
  padding: 0;
  margin: 0;
  appearance: none;
  outline: none;
  text-decoration: none;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  -webkit-border-radius: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}