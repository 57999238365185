.Checkbox {
	display: block;
	position: relative;
	margin-bottom: 15px;
	font-size: 14px;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

.Checkbox__label {
  margin-bottom: 5px;
  margin-left: 5px;
  font-size: 20px;

  .-disabled & {
    opacity: .5;
  }
}

.Checkbox__field {
  // width: 100%;
  // border: solid 1px #999;
  // padding: 5px 5px;
  // font-size: 16px;
  // outline: none;
  
  // &:focus {
  //   box-shadow: 0 0 0 3px rgba(#000, .1);
  // }
}

.Checkbox__error {
  display: 'block';
  color: 'red';
}

