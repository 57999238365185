@import '../../styles/tools.scss';

.Export {
  z-index: 9;
  position: absolute;
  text-align: left;
  width: 100%;
  height: 100%;
  // margin-left: -10px;
  padding: 5px;
  background: rgba(#333, .5);
  color: #FFF;
}

.Export__content {
  background: #333;
  border-radius: 2px;
}

.Export__date {
  padding: 15px;
}

.Export__item {
  padding: 15px;
}

.Export__title {
  @include reset-input();

  width: 100%;
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: bold;
}

.Export__link {
  display: block;
  color: #FFF;
  margin-bottom: 3px;
  padding: 3px;
  background: rgba(#FFF, .05);
  cursor: pointer;
  text-decoration: underline;
}

.Export__input {
  @include reset-input();

  width: 100%;
  margin-bottom: 3px;
  padding: 3px;
  background: rgba(#FFF, .05);
}