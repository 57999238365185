@import './styles/reset.scss';

.App {
	position: relative;
	width: 100%;
	height: 100vh;
	padding: 10px 50px 30px 50px;
	font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  // text-align: center;
}

.App__content {
	position: relative;
	width: 100%;
	height: 100%;
	max-width: 1024px;
	margin: auto;
}