@import '../../styles/tools.scss';

.Select{
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}

.Select__item{
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	padding: 3px;
}

.Select__back {
	display: flex;
	flex-direction: column;
	// flex-direction: row;
	justify-content: space-between;
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 3px;
	background: rgba(50, 50, 50, 0.2);

	&::before{
		@include noise();

		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: .2;
		border-radius: 3px;
		background: rgba(100, 100, 100, 0.7);
	}

	&::after{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 100%);
	}
}

.Select__center {
	display: block;
	margin: 5px auto;
	color: #666;

	.-small & {
		margin: auto;
	}
}