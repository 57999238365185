.Textarea {
	display: block;
	position: relative;
	margin-bottom: 15px;
	font-size: 14px;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;

  &.-disabled {
    opacity: .5;
  }
}

.Textarea__label {
  display: block;
  margin-bottom: 5px;
  font-size: 20px;
  // font-family: "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

.Textarea__field {
  width: 100%;
  border: solid 1px #999;
  padding: 5px 5px;
  font-size: 16px;
  outline: none;
  
  &:focus {
    box-shadow: 0 0 0 3px rgba(#000, .1);
  }

  .-disabled & {
    background: #DDD;
  }
}

.Textarea__error {
  display: 'block';
  color: 'red';
}

