@import '../../styles/tools.scss';

.NewTask{
	z-index: 99999;
	display: none;
	position: fixed;
	left: 50%;
	top: 1%;
	width: 80%;
	max-width: 500px;
	padding: 20px;
	background: #FFF;
	border-radius: 5px;
	transform: translateX(-50%);
	box-shadow: 1px 1px 10px rgba(0,0,0,.5);

	&.-show{
		display: block;
	}
}

.NewTask__form {
	display: flex;
	width: 100%;
	margin: 0 -10px;
}

.NewTask__form__field {
	width: 100%;
	padding: 0 10px;
}

.FormNewClient {
}

.FormNewClient__group {
	display: block;
	margin-bottom: 10px;
}

.FormNewClient__label {
	display: block;
	margin-bottom: 5px;
	font-size: 16px;
}

.FormNewClient__color {
	display: inline-block;
	margin: 0 5px 5px 0;
	width: 25px;
	height: 25px;
	border-radius: 3px;

	&:hover {
		box-shadow: 0 0 0 2px #CCC;
	}

	&.-current {
		box-shadow: 0 0 0 2px red;
	}
}

.FormNewClient__textarea {
	width: 100%;
}

.FormNewClient__checkbox {
	margin-right: 3px;
}

.FormNewClient__labelCheckbox {
	font-size: 16px;
}