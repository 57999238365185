@import '../../styles/tools.scss';

.Task{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	//overflow: hidden;
	cursor: move;
	pointer-events: auto;

	&.-noConsider {
		// opacity: .6;
	}

}

.Task__back{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 3px;
	// opacity: .5;
}

.Task__color{
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 3px;
	overflow: hidden;
	text-align: center;

	&::before{
		@include noise();

		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: .3;
	}

	&::after{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 100%);
	}


	.-noConsider & {

		opacity: .7;
	
		&::after{
			background: linear-gradient(to right, rgba(#666,.5) 0%, rgba(#333,0.8) 100%);
		}
	}

}


.Task__remove{
  @include reset-button();
  
  position: absolute;
  top: 0px;
  left: 3px;
  color: #FFF;
	padding: 8px;
	opacity: 0;
	visibility: hidden;
	transition: all 300ms ease;

	.Task:hover & {
		opacity: 1;
		visibility: visible;
	}
}

.Task__edit{
  @include reset-button();
	
	z-index: 2;
  position: absolute;
  top: 6px;
	right: 6px;
	width: 15px;
	height: 24px;
	border-radius: 2px;
  color: #FFF;
	opacity: 0;
	visibility: hidden;
	transition: all 300ms ease;

	&:hover, &.-show {
		background: rgba(#FFF, .2);
	}

	&::before {
		content: '';
		position: absolute;
		top: 5px;
		left: 50%;
		height: 2px; 
		width: 2px;
		background: #FFF;
		border-radius: 50%;
		transition: all 300ms ease;
		transform: translate(-50%, 0px);
	}

	span {
		position: absolute;
		top: 50%;
		left: 50%;
		height: 2px; 
		width: 2px;
		background: #FFF;
		border-radius: 50%;
		transition: all 300ms ease;
		transform: translate(-50%, -7px);
	}

	&::after {
		content: '';
		position: absolute;
		bottom: 5px;
		left: 50%;
		height: 2px; 
		width: 2px;
		background: #FFF;
		border-radius: 50%;
		transition: all 300ms ease;
		transform: translate(-50%, -12px);
	}

	.Task:hover & {
		opacity: 1;
		visibility: visible;
	
		span {
			transform: translate(-50%, -50%);
		}
	
		&::after {
			transform: translate(-50%, 0px);
		}
	}


	.Task.-extraSmall & {
		height: calc(100% - 12px);
		max-height: 13px;

		span {
			opacity: 0;
		}
	
		&::after {
			opacity: 0;
		}
	}
}

.Task__label{
	display: block;
	position: relative;
	width: 100%;
	margin: -1px auto 0px auto;
	padding: 0 10px;
	color: #FFF;
	font-size: 19px;
	letter-spacing: 1px;
	text-align: center;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;

	.Task.-extraSmall & {
		font-size: 17px;
	}
	.Task.-extremSmall & {
		display: none;
	}
}

.Task__times{
	display: block;
	position: relative;
	width: 100%;
	margin: -2px auto 2px auto;
	padding: 0 10px;
	color: #FFF;
	font-size: 11px;
	letter-spacing: 1px;
	text-align: center;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;

	.Task.-small & {
		display: none;
	}
}

.Task__description{
	display: block;
	position: relative;
	width: 100%;
	margin: -2px auto 2px auto;
	padding: 0 10px;
	color: rgba(#FFF, .5);
	font-size: 11px;
	letter-spacing: 1px;
	text-align: center;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;

	.Task.-extraSmall & {
		display: none;
	}
}

.Task__resize-top {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 15px;
	cursor: row-resize;
	// background: rgba(#F00,0.3);

	&::after {
		content: '';
		position: absolute;
		display: block;
		bottom: 4px;
		left: 40%;
		width: 20%;
		height: 2px;
		border-radius: 10px;
		background: rgba(#FFF, .3);
		transform: translateY(-4px);
		opacity: 0;
		transition: all 900ms ease-out;
	}

	.Task:hover &::after {
		opacity: 1;
		transform: translateY(0px);
	}

	.Task.-small & {
		height: 10px;
	}
	.Task.-small &::after {
		transform: translateY(2px);
	}
	.Task.-extraSmall & {
		height: 7px;
	}
	.Task.-extraSmall &::after {
		opacity: 0;
	}
}

.Task__resize-bottom {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 15px;
	cursor: row-resize;
	// background: rgba(#F00,0.3);

	&::after {
		content: '';
		position: absolute;
		display: block;
		top: 4px;
		left: 40%;
		width: 20%;
		height: 2px;
		border-radius: 10px;
		background: rgba(#FFF, .3);
		transform: translateY(4px);
		opacity: 0;
		transition: all 900ms ease-out;
	}

	.Task:hover &::after {
		opacity: 1;
		transform: translateY(0px);
	}

	.Task.-small & {
		height: 10px;
	}
	.Task.-small &::after {
		transform: translateY(-2px);
	}
	.Task.-extraSmall & {
		height: 7px;
	}
	.Task.-extraSmall &::after {
		opacity: 0;
	}
}

.Task__menu {
	z-index: 999;
	position: absolute;
	top: 37px;
	right: 5px;
	padding: 5px 0px;
	border-radius: 3px;
	background: #FFF;
	font-size: 14px;
	cursor: pointer;
	opacity: 0;
	visibility: hidden;
	transition: all 300ms ease-out;

	&::before {
    content:'';
    display:block;
    position:absolute;
    right: 3px; 
		bottom: 100%;
    width:0; 
		height:0;
    border: solid 6px transparent;
    border-bottom: 5px solid #FFF;
    margin-top: -5px;
	}

	.Task.-extraSmall & {
		top: 27px;
	}

	&.-show  {
		opacity: 1;
		visibility: visible;
	}

}

.Task__menu__item {
	display: block;
	padding: 5px 15px;
	color: #333;
	text-decoration: none;

	&:hover {
		background: #EEE;
	}

	&.-disabled {
		background: #FFF;
		opacity: .5;
	}

	&.-remove {
		// color: rgb(121, 0, 0);
	}
}

.Task__menu__item--rh {
	border: none;
	width: 100%;
	height: 1px;
	background: #DDD;
	margin: 3px 0;
}