@import '../../styles/tools.scss';

.Timelines {    
	display: flex;
	flex-direction: column;
	height: 100%;
}

.Timelines__nav {
	display: flex;
	flex-direction: row-reverse;
	margin-bottom: 10px;
}

.Timelines__option {
	@include reset-button();

	color: #FFF;
	// display:
	// border-top: solid 2px #FFF;
	// border-bottom: solid 2px #FFF;
}

.Timelines__date {
	display: flex;
	justify-content: space-between; 
	color: #FFF;
	text-align: center;
	padding-bottom: 15px;
	// color: grey;

	// b {
	// 	color: white;
	// }

	// & > span > span {
	// 	color: white;
	// }
}

.Timelines__date__content {
	position: relative;
	display: flex;
	align-items: center;
}
.Timelines__date__button {
	@include reset-button();
	margin-left: 10px;
	font-size: 24px;
}
.Timelines__date__menu {
	z-index: 999999;
	position: absolute;
	top: 30px;
	right: 12px;
	background-color: #333;
	padding: 15px;
	transform: translateX(50%);
	border-radius: 6px;
	max-height: calc(100vh - 50px);
	overflow: auto;
}
.Timelines__date__menu__item {
 position: relative;
 padding: 20px 70px;
 margin-bottom: 2px;
 border-radius: 3px;
 background: #FFF;

 &::before{
		@include noise();

		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: .3;
	}

	&::after{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 100%);
	}
}
.Timelines__date__menu__item__client {
	z-index: 1;
	position: relative;
	font-size: 19px;
	letter-spacing: 1px;
}
.Timelines__date__menu__item__time {
	z-index: 1;
	position: relative;
	font-size: 11px;
	letter-spacing: 1px;
}

.Timelines__previous {
	@include reset-button();
	
	&::after {
		content: '';
		display: block;
		width: 10px;
		height: 10px;
		border-top: solid 1px #FFF;
		border-left: solid 1px #FFF;
		transform: rotateZ(-45deg) translate(5px,5px);
	}
}

.Timelines__next {
	@include reset-button();
	
	&::after {
		content: '';
		display: block;
		width: 10px;
		height: 10px;
		border-top: solid 1px #FFF;
		border-right: solid 1px #FFF;
		transform: rotateZ(45deg) translate(-5px,5px);
	}
}

.Timelines__content{
	display: flex;
	height: 100%;
}